/* Generico contenitore */
.MuiGrid-root.valueContainer.MuiGrid-container {
  min-height: 640px !important;
  margin-bottom: -100px;
  display: flex !important;
  flex-direction: column;
  background: #fff;
  padding: 50px 50px 30px 66px;
  border-radius: 6px;
  -webkit-box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.75);
  z-index: 80;
  position: relative;
}

@media screen and (max-width: 480px) {
  .impattoStringContainer p,
  .presidiStringContainer p,
  .probabilitaStringContainer p {
    font-size: 0.5rem !important;
  }
  .impattoValueContainer p,
  .presidiValueContainer p,
  .probabilitaValueContainer p {
    font-size: 0.5rem !important;
  }
  .MuiGrid-root.valueContainer.MuiGrid-container {
    padding: 0px;
    padding-top: 50px;
  }
  .MuiStep-horizontal {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.riskStepper.MuiStepper-alternativeLabel.MuiPaper-elevation0 {
    margin-bottom: -35px !important;
  }
  p.MuiTypography-root.riskStepLabel.MuiTypography-body1 {
    font-size: 0.8rem !important;
  }
  .MuiTypography-h6 {
    font-size: 0.9rem !important;
  }
  .MuiTypography-h5 {
    font-size: 0.8rem !important;
  }
  .tableHeaderItem {
    margin-bottom: 20px !important;
  }
}

.MuiGrid-root.riskBigContainerWrapper.MuiGrid-container.MuiGrid-justify-xs-center {
  min-height: 50vh;
}
.riskValutazioneContent {
  top: 60px;
}

.MuiGrid-root.stepContainer.MuiGrid-container.MuiGrid-grid-xs-12 {
  flex: 1;
}

.graphContainer {
  align-self: center;
}

.titleRiskResult {
  margin-left: -16px !important;
  text-transform: uppercase !important;
  color: #3a3838 !important;
  font-weight: bold !important;
}

span.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
  background: #fff;
  border-radius: 50px;
}
span.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
  zoom: 1.6;
}

span.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
  z-index: 90;
}

.gridFooterGray {
  height: 20vh;
  background-color: #f2f2f2;
}

.riskValutazioneFooter {
  align-self: flex-end;
}
/*Bullet Step*/
.MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.riskStepper.MuiStepper-alternativeLabel.MuiPaper-elevation0 {
  background-color: transparent;
  margin-top: 5x;
  margin-bottom: -44px;
}

text.MuiStepIcon-text {
  font-weight: 500;
  fill: #fff;
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
/*
.MuiStepIcon-root {
  color: #fff !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: #154b44 !important;
}
.MuiStepIcon-root.MuiStepIcon-completed {
  color: #154b44 !important;
}
text.MuiStepIcon-text {
  fill: #154b44 !important;
}
.MuiStepIcon-active text.MuiStepIcon-text {
  fill: #fff !important;
} */

p.MuiTypography-root.riskStepLabel.MuiTypography-body1 {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-bottom: 10px;
}

.riskStep
  .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel {
  top: 55px !important;
}
.MuiGrid-root.risksStep.MuiGrid-item.MuiGrid-grid-xs-12 {
  z-index: 90;
}

/*Step Valutazione*/

.leftBar {
  height: 35px !important;
  text-align: center;
  background-color: #4ead5b !important;
}

.centerBar {
  height: 35px !important;
  text-align: center;
  background-color: #f4bb4e !important;
}

.rightBar {
  height: 35px !important;
  text-align: center;
  background-color: #e55c5d !important;
}

.impattoStringContainer p,
.probabilitaStringContainer p,
.presidiStringContainer p {
  text-align: left;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  font-size: 1rem;
  color: #3a3838 !important;
}

.impattoValueContainer p,
.probabilitaValueContainer p,
.presidiValueContainer p {
  text-align: left;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  font-size: 1rem;
  color: #757171 !important;
}

.riskBigContainerWrapper h6.MuiTypography-root.MuiTypography-subtitle1 {
  font-weight: 300 !important;
}

.riskBigContainerWrapper .leftBar h6.MuiTypography-root.MuiTypography-subtitle1,
.riskBigContainerWrapper
  .centerBar
  h6.MuiTypography-root.MuiTypography-subtitle1,
.riskBigContainerWrapper
  .rightBar
  h6.MuiTypography-root.MuiTypography-subtitle1 {
  font-weight: 900 !important;
  margin-top: 5px;
  color: #fff !important;
}

.riepilogoContainer {
  border-bottom: 1px solid #d0cece !important;
  padding: 20px 0px;
}

.riskBigContainerWrapper div#panel1a-header {
  padding: 0px;
}
.headerContainer {
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  padding-left: 5%;
  padding-right: 5%;
}
.valutazioneValueNumber {
  font-weight: bold !important;
  margin-bottom: -10px !important;
}

/*Modal Note*/

/* .modalNote h2.MuiTypography-root.MuiTypography-h6 {
  color: #154b44;
} */

.modalNote p.MuiTypography-root.MuiTypography-body1 {
  font-weight: 500;
  padding-bottom: 10px;
}

.modalNote
  .MuiInputBase-root.MuiInput-root.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-multiline.MuiInput-multiline {
  border: 1px solid #d0cece !important;
  border-radius: 5px !important;
  padding: 10px 10px !important;
}

.modalNote
  .MuiGrid-root.notesModalButtonsContainer.MuiGrid-container.MuiGrid-grid-xs-12 {
  padding: 30px 0px 20px 0px;
  display: flex;
  justify-content: flex-end;
}

.modalNote
  button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.notesModalBackButton.MuiButton-outlinedPrimary {
  margin-right: 20px;
}

/*input valutazioni*/

.riskValutazioneContent
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-marginDense.MuiInput-marginDense {
  padding: 0px 5px 5px 23px;
  margin-left: 30px !important;
}

.riskValutazioneContent .MuiInput-underline:before {
  padding: 16px 0px 6px 0px;
  content: "\00a0";
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.22);
  pointer-events: none;
  border-radius: 5px;
}
.riskValutazioneContent .MuiInput-underline:after {
  padding: 33px 0px 6px 0px;
  border: 2px solid #154b44;
  pointer-events: none;
  border-radius: 5px;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-center.MuiGrid-align-content-xs-center.MuiGrid-justify-xs-center.MuiGrid-grid-xs-12 {
  margin: 20px !important;
}

/* spinner loading */
.riskBigContainerWrapper
  .MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate {
  position: absolute;
  top: 50%;
}

/* alert */
.alertRisk {
  padding: 20px;
  max-width: 90% !important;
}

.MuiGrid-root.warningContainer.MuiGrid-container.titoloAlert {
  padding: 20px 20px 0px 20px !important;
}

.titoloAlert h6.MuiTypography-root.MuiTypography-h6 {
  font-size: 1.5rem;
  font-weight: bold;
}

.alertRisk svg.MuiSvgIcon-root {
  fill: orange;
  width: 2rem;
  height: 2rem;
  margin-top: 2px;
  margin-right: 10px;
}
.alertRisk h2.MuiTypography-root.MuiTypography-h6 {
  font-weight: 100;
}

@-moz-document url-prefix() {
  .riskValutazioneContent
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-marginDense.MuiInput-marginDense {
    width: 50%;
  }
  .riskBigContainerWrapper
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-marginDense.MuiInput-marginDense {
    width: 50%;
  }
  .riskBigContainerWrapper
    .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    width: 40px !important;
    height: 40px !important;
    margin-top: -6px;
    margin-left: -8px;
  }
  .riskBigContainerWrapper
    .MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active {
    width: 40px !important;
    height: 40px !important;
  }
  .riskBigContainerWrapper .MuiSvgIcon-root.MuiStepIcon-root {
    height: 40px !important;
    width: 40px !important;
  }
  .riskBigContainerWrapper
    .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
    margin-top: -2px;
  }
}
.ql-formats span.ql-size.ql-picker {
  display: none !important;
}