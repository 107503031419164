html,
body {
  width: 100%;
  overflow: auto;
}

/*.custom-dropzone,
.custom-dropzone-no-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fafafa !important;
  margin: 1rem;
  width: auto;
}
.custom-dropzone p,
.custom-dropzone-no-text p {
  font-size: 18px !important;
  color: #444444;
}
.custom-dropzone-no-text > div:first-child svg {
  display: none;
}

.custom-dropzone > div,
.custom-dropzone-no-text > div {
  justify-content: center;
  margin: 0;
  width: 100%;
}*/

:disabled {
  color: #666 !important;
}

.ql-editor {
  max-height: 300px;
  overflow: auto;
}

.protocolli .ql-editor{
  min-height: 250px;
  max-height: 500px;
  overflow: auto;
}

.RiskAssessmentMatrixReati .MuiAccordionSummary-content {
  align-items: center;
}

.RiskAssessmentMatrixReati .MuiCollapse-container .MuiFormControlLabel-label {
  flex: 1;
}

.Popover-Custom .MuiPopover-paper {
  background-color: rgba(32, 32, 32, .75) !important;
  padding: 7px 14px !important;
  color: #fff !important;
}